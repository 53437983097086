import React, { useState } from 'react';
import { Row, Layout, Col, Form, Input, Button, Typography } from 'antd';
import { UserOutlined, PlusOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined, RestOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import EmptyState from '../images/emptystate.svg';

const { Content, Header } = Layout;

const { Title, Text, Paragraph } = Typography;

const UserContacts = () => {

  const [showCF, setShowCF] = useState(false);
  const { user } = useSelector(state => state);

  const queryParams = {
    id: user.id,
  };

  const {
    loading,
    data,
  } = useQuery(GET_USER_CONTACTS, {
    variables: queryParams,
  });

  const [addContact] = useMutation(SAVE_USER_CONTACT, {
    update(cache, { data: { createContact: { contact: new_contact } } }) {
      cache.modify({
        fields: {
          contacts(existingContacts = []) {
            const newContactRef = cache.writeFragment({
              data: new_contact,
              fragment: gql`
                fragment NewContact on Contact {
                  id
                }
              `
            });
            return [...existingContacts, newContactRef];
          }
        }
      });
    }
  });

  const [removeContact] = useMutation(DELETE_USER_CONTACT, {
    update(cache, { data: { deleteContact: { contact: deleted_contact } } }) {
      cache.modify({
        fields: {
          contacts(existingContacts = []) {
            const contactRef = cache.writeFragment({
              data: deleted_contact,
              fragment: gql`
                fragment NewContact on Contact {
                  id
                }
              `
            });
            const newC = existingContacts.filter((c) => c.__ref !== contactRef.__ref);
            return [...newC];
          }
        }
      });
    }
  });

  const putContact = async contact => {
    try {
      const dup = data && data.contacts.includes(contact);

      if (!dup) {
        addContact({
          variables: { contact: { data: { ...contact, user: user.id } } },
        });
        setShowCF(false);
      }
    } catch (e) {
      console.log('DEBUG error', e);
    }
  };

  const deleteContact = contact => {
    removeContact({
      variables: { contact: { where: { id: contact.id } } }
    });
  };

  return (
    <Layout className="content-contact">
      <Header className="site-layout-background header" style={{ padding: 0 }}>
        <Button
          style={{ textAlign: 'left' }}
          className="icon-color-primary"
          type="text"
          size="large" href="/home" icon={<ArrowLeftOutlined />} />
        <Text strong>
          Contactos de emergencia
        </Text>
      </Header>
      <Content className="site-layout-background">

        {data && data.contacts &&
        !!data.contacts.length ? (
            data.contacts.map(d => (
              <Row
                className="background-primary-medium"
                justify="space-around"
                align="middle"
                style={{ marginBottom: '3px' }}
                key={d.id}>
                <Col style={{ border: 'none' }} push={1} xs={4} sm={4} md={6} lg={8} xl={10}>
                  <Title >
                    <UserOutlined
                      className="icon-color-primary"
                      style={{ fontSize: '30px', marginTop: 19 }}
                    />
                  </Title>
                </Col>
                <Col push={1} xs={15} sm={16} md={12} lg={8} xl={4}>
                  <p>
                    <Text strong>{d.fullname}</Text> <br /> {d.email} <br /> {d.phone}
                  </p>
                </Col>
                <Col push={1} xs={5} sm={4} md={6} lg={8} xl={10}>
                  <Button
                    className="background-primary-medium icon-color-primary"
                    size="large"
                    style={{ border: 'none' }}
                    onClick={() => deleteContact(d)}
                    icon={<RestOutlined />} />
                </Col>
              </Row>
            ))
          ) :

          <Row justify="center">
            <Col>
              <img src={EmptyState} alt="emptyState"></img>
            </Col>
            <Col style={{ textAlign: 'center' }} span={22}>
              <Paragraph className="text-primary" level={5}>
                Aún no has registrado contactos, añadir uno nuevo
              </Paragraph>
            </Col>
          </Row>

        }

        <br />
        <Row justify="center">
          <Button
            className="botton"
            type="primary"
            shape="circle"
            onClick={() => {
              setShowCF(!showCF);
            }}
            icon={<PlusOutlined />}
          />
        </Row>
        <br />
        {showCF && (
          <Row style={{ textAlign: 'center' }}>
            <Col span={24}>
              <Title className="text-primary" level={5}>Agregar un contacto nuevo</Title>
            </Col>
            <Col span={24}>
              <Form name="add_contact" onFinish={putContact}>
                <Form.Item
                  name="fullname"
                  rules={[{ required: true, message: 'Por favor ingrese un nombre válido' }]}>
                  <Input
                    className="background-primary-medium"
                    prefix={<UserOutlined className="icon-color-primary" />}
                    bordered={false}
                    placeholder="Nombre de contacto"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Por favor ingrese su un email válido' }]}>
                  <Input
                    className="background-primary-medium"
                    prefix={<MailOutlined className="icon-color-primary" />}
                    placeholder="Email"
                    bordered={false}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: 'Por favor ingrese un número de teléfono válido' },
                  ]}>
                  <Input
                    className="background-primary-medium"
                    prefix={<PhoneOutlined className="icon-color-primary" />}
                    placeholder="Celular"
                    bordered={false}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  rules={[{ required: true, message: 'Por favor ingrese una dirección válida' }]}>
                  <Input.TextArea
                    size="large"
                    className="background-primary-medium"
                    placeholder="Dirección"
                    bordered={false}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Guardar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </Content>
    </Layout>
  );
};

const GET_USER_CONTACTS = gql`
  query MyContacts($id: ID) {
    contacts(where: { user: $id }) {
      address
      email
      fullname
      id
      user {
        id
        email
      }
    }
  }
`;

const SAVE_USER_CONTACT = gql`
  mutation createContact($contact: createContactInput) {
    createContact(input: $contact) {
      contact {
        fullname
        phone
        id
        address
      }
    }
  }
`;

const DELETE_USER_CONTACT = gql`
  mutation deleteContac($contact: deleteContactInput) {
    deleteContact(input: $contact) {
      contact {
        id
        phone
        address
        fullname
      }
    }
  }
`;

UserContacts.propTypes = {
  location: PropTypes.object,
};

export default UserContacts;
